import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import "../../css/custom-css/custom-products-services.css"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/products/banner-produk.png"
import Seo from "../../components/seo"

const IndigenRealtime = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2" />

      <Header productsPage />

      <BannerHeader
        background={bg}
        title="INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="">
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/products/indigen-real-time">
                        INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/diago-t790m">
                        DIAGO T790M Mutation Detection Kit
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/products/indigenDESC.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div class="row m-b30">
                      <div class="col-lg-12">
                        <div class="dlab-tabs  product-description tabs-site-button">
                          <h4 className="dlab-title mt-2">
                            INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                          </h4>
                          <Tabs>
                            <TabList className="nav nav-tabs ">
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Description</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Test Principle</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Intended Use</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Additional Information</Link>
                              </Tab>
                            </TabList>

                            <TabPanel class="tab-pane">
                              <p className="text-justify">
                                Mycobacterium tuberculosis is a bacterial
                                species of pathogen belonging to the genus
                                Mycobacterium, and is the causative agent of
                                tuberculosis (TB). TB disease in Indonesia is
                                ranked 3rd with 824 thousand cases and 93
                                thousand deaths per year or equivalent to 11
                                deaths per hour. In line with the program of the
                                Indonesian government and WHO in eliminating TB
                                2030, PT KalGen DNA presents an innovative real
                                time PCR-based TB detection test, INDIGEN
                                MTB/NTM/DR-TB Real Time PCR Kit Gen. 2. INDIGEN
                                Kit is an in-vitro diagnostic kit that has the
                                ability to detect Mycobacterium tuberculosis
                                (MTB), Nontuberculous mycobacteria (NTM),
                                rifampicin and isoniazid resistance using a
                                PCR-based method. One kit includes extraction
                                and a real-time PCR kit that makes operation
                                easy in your laboratory. INDIGEN MTB/NTM/DR-TB
                                Real Time PCR Kit Gen. 2 is an open system
                                real-time PCR machine that has been used
                                nationally after the Covid-19 pandemic (PCR
                                Utilization).
                              </p>
                              <StaticImage
                                src="../../images/products/indigenTBDesc.jpeg"
                                layout="fullWidth"
                                height={300}
                              />
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <StaticImage
                                src="../../images/products/indigenUse.png"
                                layout="fullWidth"
                                height={300}
                              />
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                INDIGEN MTB/NTM/DR-TB Real-time PCR Kit Gen. 2
                                is an in-vitro diagnostic test intended for the
                                qualitative detection of nucleic acid from the
                                Mycobacteria tuberculosis, Non-tuberculous
                                mycobacteria (NTM) and also detection of
                                resistance from rifampicin and isoniazid in
                                sputum specimen collected from individual
                                suspected TB symptom. This kit requires a
                                well-trained molecular analyst to run in a
                                biomolecular laboratory.
                              </p>
                            </TabPanel>

                            <TabPanel>
                              <p class="m-b10 font-weight-bold">
                                Reference Article:
                              </p>
                              <p className="ml-4">
                                <ul>
                                  <li>
                                    Dunn,J.J., Jeffrey,R.S., Paula,A. Laboratory
                                    Diagnosis of Mycobacterium tuberculosis
                                    Infection and Disease in Children.
                                    J.Clinical Microbiology 54, 6 (2016)
                                  </li>
                                  <li>
                                    <a
                                      href="https://tbindonesia.or.id/"
                                      target="_blank"
                                    >
                                      https://tbindonesia.or.id/
                                    </a>
                                  </li>
                                </ul>
                              </p>
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default IndigenRealtime
